* {
  scroll-margin-top: 75px !important;
}

body {
  margin: 0;
  background-color: #0D1117 !important;
  font-family: -apple-system, "system-ui", "Segoe UI", Helvetica, Arial, sans-serif, "Apple Color Emoji", "Segoe UI Emoji";
  color: #C9D1D9 !important;
}

.avatar {
  width: 80%;
  border-radius: 100%;
  margin-top: 5%;
  margin-left: 10%;
  border: 1px solid #2F353B;
  position: relative !important;
}

.name {
  color: #C9D1D9 !important; 
  margin-left: 10%;
}

.username {
  margin-top: -20px;
  margin-left: 10%;
  font-weight: 300;
  color: #8C949E !important;
  font-size: 20px;
}

.cv {
  margin-top: -20px;
  margin-left: 10%;
  font-weight: 300;
  color: #C9D1D9 !important;
  font-size: 20px;
  background: #21262D !important;
  border-color: #373B42 !important;
  width: 80%;
}
.cv:hover {
  border-color: #3a3f47 !important;
}
.email {
  margin-left: 10%;
  margin-top: 15px;
}

.link {
  margin-top: 10px;
  margin-left: 10%;
}

.anchor {
  color: #C9D1D9 !important;
}

.anchor:hover {
  color: #58A6FF !important;
  text-decoration: underline;
}

.icon {
  font-size: 20px;
  top: 6px;
  position: relative;
  color: #8C949E !important;
}

.header-menu-icon {
  font-size: 17px;
  top: 4px;
  position: relative;
  margin-right: 5px !important;
}

.header {
  position: fixed;
  width: 100%;
  background-color: #0D1117 !important;
  border-bottom: 1.5px solid #21262D;
  z-index: 99 !important;
}

.overview {
  margin-top: 100px;
  height: auto;
  border-radius: 10px;
  border: 1.5px solid #21262D;
  color: #C9D1D9 !important;
}
.overview h1 {
  color: #C9D1D9 !important;
  margin-top: 25px;
}
.overview h2 {
  color: #C9D1D9 !important;
  margin-top: 25px;
}
.waving-hand {
  animation-name: wave-animation;
  animation-duration: 2.5s;
  animation-iteration-count: infinite;
  transform-origin: 70% 70%;
  display: inline-block;
}

@keyframes wave-animation {
  0% { transform: rotate( 0.0deg) }
  15% { transform: rotate(14.0deg) }  /* The following five values can be played with to make the waving more or less extreme */
  30% { transform: rotate(-8.0deg) }
  40% { transform: rotate(14.0deg) }
  50% { transform: rotate(-4.0deg) }
  60% { transform: rotate(10.0deg) }
  70% { transform: rotate( 0.0deg) }  /* Reset for the last half to pause */
 100% { transform: rotate( 0.0deg) }
}

.divider {
  background-color: #30363D;
}

.project-divider {
  background-color: #30363D;
  margin-left: -15px !important;
  width: calc(100% + 15px) !important;
}

.overview-content {
  padding-left: 15px;
}

.about-me-item {
  margin-top: -15px;
}
.header-menu {
  background: #0D1117 !important;
  color: #C9D1D9 !important;
  border-bottom:#21262D !important;
}

.header-menu-item {
  color: #C9D1D9 !important;
}

.language {
  width:75px;
  border: none !important;
  cursor: default !important;
}
.lang-icon > * {
  font-size: 17px;
  top: 3px;
  position: relative;
}

.projects h2 {
  color: #C9D1D9 !important;
}
.project {
  width: 95%;
  border: 1.5px solid #21262D;
  padding: 15px !important;
  border-radius: 10px;
  cursor: pointer;
  margin-bottom: 15px;
}
.project-description {
  font-size: 10px !important;
  margin-top: 10px;
  color:#858e98 !important;
}
.project-lang {
  color: #838c96 !important;
  margin-top: 5px; 
  font-size: 12px;
}
.project:hover{
  border: 1.5px solid #3a3f47 !important;
}
.cert-description {
  font-size: 10px !important;
  color:#858e98 !important;
  margin-top: 5px !important;
  margin-bottom: -5px !important;
}

.ant-modal-header {
  background-color: #1F1F1F !important;
  border-bottom: 1px solid #303030 !important;
  font-family: 'Paytone One', sans-serif !important;
}
.ant-modal-title, .ant-modal-close {
  color: #DEDEDE !important;
}
.ant-modal-footer {
  background-color: #1F1F1F !important;
  border-top: 1px solid #303030 !important;
}
.ant-modal-footer .ant-btn {
  color: #DEDEDE !important;
}
.ant-tabs-tab-btn {
  margin-top: 15px !important;
  color: #dedede !important;
}
.project-modal-data {
  color: #C9D1D9 !important;
}
.ant-menu-sub {
  background-color: #0D1117 !important;
  border: 1px solid #3a3f47 !important;
}
.ant-back-top-content {
  border: 1px solid #3a3f47 !important;
  color: #C9D1D9 !important;
}
.ant-menu-item-active, .ant-menu-item-selected {
  background-color: rgba(0,0,0,0) !important;
}
.ant-tabs-nav-wrap {
  border-bottom:1px solid #0D1117 !important;
  color: #C9D1D9 !important;
}
.content-wrapper {
  margin-left: 15px;
}
.cert-name {
  color: #C9D1D9 !important;
}